import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <p style={{fontWeight: 'bold', fontSize: '2.3em'}}>
          Welcome to Lingua Antarctica!
        </p>
        <p style={{fontStyle: 'italic', fontSize: '1.2em'}}>
          Coming soon, to a browser near you...
        </p>
      </header>
    </div>
  );
}

export default App;
